export const fineData = {
    reportOptions : [],
    startList : [
        {name:'周期',text: 'currentWeek'},
        {name:'文件ID',text: 'fileId'},
        {name:'报告名称',text: 'name'},
        // {name:'报告类型',text: 'fileTypeName'},
        {name:'报告状态',text: 'fileStateName'},
        {name:'报告上传者',text: 'userName'},
        {name:'上传时间',text: 'date'},
    ],
    currentPage:1,
    pageSize: 10,
    total: 0,
    loading: true,
    spreadTable:[],
    tableHeight: 400,
    operation: ['查看','评为优秀'],
    pageType: 1,
    lastState: 1000,
    filetype: null,

    reportValue: undefined,
    fileName: undefined,
    datePicker: undefined,
    startTime:undefined,
    endTime: undefined,

    showHtml: false,
    reportHtml: '',
}